import COMMON from "common";
import services from "services";
import encodeQueryParams from "common/encode-query-params";

const api = {
	get: {
		general: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.LIST + encodeQueryParams(params)),
			states: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.STATES + encodeQueryParams(params)),
			cities: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.CITIES + encodeQueryParams(params)),
			postcode: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.POSTCODE + encodeQueryParams(params)),
		},
		motor: {
			paymentStatus: (payload) => services.get(COMMON.ENDPOINT_PATH.INSURER.PAYMENT_STATUS + encodeQueryParams(payload), { cancelId: COMMON.ENDPOINT_PATH.INSURER.PAYMENT_STATUS }),
		},
	},
	post: {
		pa: {
			fullmanualEnquiry: (payload) => services.post(COMMON.ENDPOINT_PATH.INSURER.FULL_MANUAL_ENQUIRY , payload),
			halfmanualEnquiry: (payload) => services.post(COMMON.ENDPOINT_PATH.INSURER.HALF_MANUAL_ENQUIRY , payload),
			checkToken: (payload) => services.post(COMMON.ENDPOINT_PATH.INSURER.CHECK_TOKEN, payload),
		},
	},
	patch: {},
	put: {},
};

export default api;
