import React, { useMemo, useCallback, useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import COMMON from "common";
import api from "services/api";
import ERRORS from "common/errors";
import pathnames from "routes/pathnames";
import { getDateOfBirthNRICNew } from "common/nric-helpers";
import serveRequestErrors from "common/serve-request-errors";
import formatInputString from "common/format-input-string";
import formatInputvNumString from "common/format-inputvn-string";
import formatPassportString from "common/format-passport-string";
import formatEmailString from "common/format-email-string";
import AppInput from "components/app-input";
import AppSelectInput from "components/app-select-input";
import AppMaskingInput from "components/app-masking-input";
import getIdentificationListing from "services/get-identification-listing";
import getCountryListing from "services/get-country-listing";
import AppMobileInput from "components/app-mobile-input";
import AppDateInput from "components/app-date-input";
//import AppIdScan from "components/app-id-scan"
import AppPopOut from "components/app-pop-out"
import { setPersonalForm, setReceipt} from "store/slices/form";
import getOccupationListing from "services/get-occupation-listing";


const PagePaForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const form = useSelector((state) => state.form);
	const [messageType, setMessageType] = useState("");
	const [scan, setScan] = useState(true);
	var yes = true;
	//const location = useLocation();
	//const urlParams = new URLSearchParams(location.search);
	//const referralCode = urlParams.get("myReferralCode") || "";

	

	const initialValues = useMemo(() => {
		const info = form?.personalForm;
		const isInfoPassport = info?.identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT;

		let payload = {
			frontImage: null,
			rearImage: null,
			fullName: "",
			identificationType: "NRIC",
			passport: "",
			nric: process.env.REACT_APP_ID,
			//dob: new Date(1990, 0, 1),
			dob: "01/01/1990",
			nationality: "",
			email: "",
			mobileNo: "",
			prefixNo: "",
			occupation: "",
			instituteName: "",
			studentID: "",
			socialHandler: "",
		};

		if (info) {
			
			setScan(false);

			if (info.identificationType) payload.identificationType = info.identificationType;

			if (info.identificationNo) {
				if (isInfoPassport) {
					payload.passport = info.identificationNo;
				} else {
					payload.nric = info.identificationNo;
				}
			}

			if (info.fullName) payload.fullName = info.fullName;

			if (info.dob) payload.dob = info.dob;

			if (info.nationality) payload.nationality = info.nationality;

			if (info.email) payload.email = info.email;
			
			if (info.mobileNo) payload.mobileNo = info.mobileNo;
			
			if (info.prefixNo) payload.prefixNo = info.prefixNo;

			if (info.occupation) payload.occupation = info.occupation;
			
			if (info.instituteName) payload.instituteName = info.instituteName;
			
			if (info.studentID) payload.studentID = info.studentID;

			//if(info.frontImage) payload.frontImage = info.frontImage;

			//if(info.rearImage) payload.rearImage = info.rearImage;

			//if(info.socialHandler) payload.socialHandler = info.socialHandler;
		}

		return payload;
	}, [form]);

	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			/*
			frontImage: yup.mixed().required('Image is required'),
			rearImage: yup.string().when(["identificationType"], {
				is: (identificationType) => identificationType === COMMON.IDENTIFICATION_TYPE.NRIC,
				then: () => yup.mixed().required('Image is required'),
				otherwise: () => yup.mixed().notRequired(),
			  }),
			  */
			fullName: yup.string().required(ERRORS.REQUIRED),
			identificationType: yup.string().required(ERRORS.REQUIRED),
			passport: yup.string().when(["identificationType"], {
				is: (identificationType) => identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
			nric: yup.string().when(["identificationType"], {
				is: (identificationType) => identificationType === COMMON.IDENTIFICATION_TYPE.NRIC,
				then: () => yup.string().required(ERRORS.REQUIRED).min(14, ERRORS.NRIC).max(14, ERRORS.NRIC),
			}),
			dob: yup.string().required(ERRORS.REQUIRED),
			nationality: yup.string().required(ERRORS.REQUIRED),
			email: yup.string().required(ERRORS.REQUIRED),
			mobileNo: yup.string().required(ERRORS.REQUIRED).min(9, ERRORS.MOBILE),
			//socialHandler: yup.string().required(ERRORS.REQUIRED),
			occupation: yup.string().required(ERRORS.REQUIRED),
			instituteName: yup.string().when("occupation", {
				is: (occupation) => occupation === "STUDENTS",
				then: () => yup.string().required(ERRORS.REQUIRED),
				otherwise: () => yup.string(),
			  }),
			  studentID: yup.string().when("occupation", {
				is: (occupation) => occupation === "STUDENTS",
				then: () => yup.string().required(ERRORS.REQUIRED),
				otherwise: () => yup.string(),
			  }),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});


	const isPassport = useMemo(() => formik.values.identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT, [formik.values.identificationType]);
	const validIdentificationType = useMemo(() => !!formik.values.identificationType, [formik.values.identificationType]);

	const onHandleNavigateNext = useCallback(() => {
		navigate(pathnames.beForm);
	}, [navigate]);

	const onHandleNavigateReceipt= useCallback(() => {
		navigate(pathnames.receipt);
	}, [navigate]);

	const onHandleSubmit = async (values) => {
		let response = null;

		try {
			let payload = {
				//frontImage: values.frontImage,
				//rearImage: values.rearImage,
				fullName: values.fullName,
				identificationType: values.identificationType,
				identificationNo: isPassport ? values.passport : values.nric,
				//dob: isPassport ? null : getDateOfBirthNRIC(values.nric),
				dob: new Date(parseDateString(values.dob)),
				nationality: values.nationality,
				email: values.email,
				mobileNo: values.prefixNo + values.mobileNo,
				occupation: values.occupation,
				instituteName: values.instituteName,
				studentID: values.studentID,
				forms: "pa",
				status: "PENDING",
				//socialHandler: values.socialHandler,
			};
			dispatch(setPersonalForm(payload));
			//if (referralCode && referralCode !== "null") dispatch(setReferralCode(referralCode));
			console.log(yes);

			if(!yes)
			{
				const referralCode = form?.referralCode;

				let newPayload = {
					PersonalInfo: payload,
					ReferralCode: {referralCode: referralCode},
				}

				response = await api.post.pa.halfmanualEnquiry(newPayload);
			}	
		} catch (error) {
			
			if (!error?.response?.data?.errorCode) {
				if (error?.code === COMMON.ERRORS.NETWORK_ERROR) {
					setMessageType(COMMON.MESSAGE_TYPE.NETWORK);
				} else if (error?.code === COMMON.ERRORS.BE_BAD_RESPONSE) {
					setMessageType(COMMON.MESSAGE_TYPE.INVALID);
				}
			} else {
				serveRequestErrors(error);
			}
		} finally {
			formik.setSubmitting(false);
		}

		sessionStorage.setItem('fromPa', 'true');

		if(yes)
		{
			onHandleNavigateNext();
		}

		if(response && !yes)
		{
			dispatch(setReceipt(response));
			onHandleNavigateReceipt();
		}
	};

	

	const IdentificationField = useCallback((obj) => {
		if (!obj.isPassport && obj.validIdentificationType) {
			/* prettier-ignore */
			return <AppMaskingInput required type="text" name="nric" label="NRIC No." placeholder="e.g. 901010-14-1234" disabled={obj.disabled} value={obj.values.nric} error={obj.errors.nric} touched={obj.touched.nric} onChange={obj.onChange} format={COMMON.MASKING.NRIC} />
		}

		if (obj.isPassport && obj.validIdentificationType) {
			/* prettier-ignore */
			return <AppInput required type="text" name="passport" label="Passport" placeholder="e.g. VD1289281" disabled={obj.disabled} value={obj.values.passport} error={obj.errors.passport} touched={obj.touched.passport} onFormat={formatPassportString} onChange={obj.onChange} />
		}

		return null;
	}, []);

	const parseDateString = (dateString) => {
		const [day, month, year] = dateString.split('/').map(Number);
		// Note: Months are 0-indexed in JavaScript Date objects
		return new Date(Date.UTC(year, month - 1, day));
	} ;

	/*
	const handleExtractData = (data) => {
		if(scan)
		{
			const typeCharacter = (field, value, index = 0) => {
				if (index < value.length) {
				  setTimeout(() => {
					formik.setFieldValue(field, value.slice(0, index + 1));
					typeCharacter(field, value, index + 1);
				  }, 100);
				}
			  };
	  
			  //console.log(data);
		  
			  typeCharacter("fullName", data.name);
			  typeCharacter("nric", data.icNumber);
		  
			  if (formik.values.identificationType === "NRIC") {
				formik.setFieldValue("nationality", "Malaysia");

				if(getDateOfBirthNRICNew(data.icNumber))
				{
					formik.setFieldValue("dob", getDateOfBirthNRICNew(data.icNumber));
				}
			  } else if (formik.values.identificationType === "PASSPORT") {
				formik.setFieldValue("nationality", "");
			  }

			  setScan(false);
		}
		
	  };


	  //Add Watermark to image
	  const addWatermark = (ctx, text, width, height) => {
		ctx.font = "50px Arial";
		ctx.fillStyle = "rgba(0, 0, 0, 3)";
		ctx.textAlign = "right";
		ctx.fillText(text, width - 10, height - 50);
	  };


	  //Upload image function here
	  const handleImageUpload = (event, name) => {
		const image = event.target.files[0];
	
		if (image == null) {
			return;
		}
	
		const maxFileSize = 15 * 1024 * 1024; // 15 MB
		const maxWidth = 1280; // Maximum width in pixels
		const maxHeight = 720; // Maximum height in pixels
	
		// Check file size
		if (image.size > maxFileSize) {
			alert(`File size exceeds 15 MB. Please upload a smaller image. Current size: ${(image.size / (1024 * 1024)).toFixed(2)} MB`);
			return;
		}
	
		const img = new Image();
		img.src = URL.createObjectURL(image);
		img.onload = () => {
			// Create a canvas to draw the image and watermark
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
	
			// Calculate scaling factor to fit within maxWidth and maxHeight
			let scaleFactor = Math.min(maxWidth / img.width, maxHeight / img.height, 1);
	
			// Set canvas size to the scaled dimensions
			canvas.width = img.width * scaleFactor;
			canvas.height = img.height * scaleFactor;
	
			// Draw the image on the canvas with scaling
			ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
	
			// Add watermark
			addWatermark(ctx, "For easycover.my use only", canvas.width, canvas.height);
	
			// Convert the canvas to a data URL and set it as the selected image
			formik.setFieldValue(name, canvas.toDataURL('image/jpeg', 0.9));
	
			if (name === "frontImage") {
				setScan(true);
			}
	
			// Optional: Log the resizing information
			if (scaleFactor < 1) {
				console.log(`Image resized from ${img.width}x${img.height} to ${canvas.width}x${canvas.height}`);
			}
		};
	};

	*/

	  const OnHandleNoFunc = () => {
		yes = false;
		//console.log(yes);
		formik.handleSubmit();
	  };

	  const OnHandleYesFunc = () => {
		yes = true;
		//console.log(yes);
		formik.handleSubmit();
	  };

	  useEffect(() => {
		const nric = formik.values.nric;
		if (formik.values.identificationType === COMMON.IDENTIFICATION_TYPE.NRIC && nric.length === 14) {
		  const dob = getDateOfBirthNRICNew(nric);
		  if (dob) {
			formik.setFieldValue("dob", dob);
		  }
		}
	  }, [formik.values.nric, formik.values.identificationType]);


	  useEffect(() => {
		// Check if the session flag is set
		const fromPa = sessionStorage.getItem('fromDescript');
	
		if (!fromPa) {
		  // If no session flag, redirect to home page
		  navigate(pathnames.home, { replace: true });
		}
	
		// Add event listener to handle page refresh
		const handleBeforeUnload = () => {
		  sessionStorage.removeItem('fromDescript');
		};
	
		window.addEventListener('beforeunload', handleBeforeUnload);
	
		// Cleanup the event listener on component unmount
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	  }, [navigate]);

	  

	return (
		<div className="page-pa-form">
			<div className="enquiry">
				<form className="enquiry__form" onSubmit={formik.handleSubmit}>
					<div className="enquiry__container">
						<div className="enquiry__box">
							{!messageType && !formik.isSubmitting && (
								<div className="enquiry__wrapper">
									<h1 className="enquiry__title">Personal Insurance</h1>
									<p className="enquiry__description">Submit your information to apply for personal insurance.</p>

									<div className="enquiry__box-body">
										
										{/* prettier-ignore */}
										<AppInput required type="text" name="fullName" label="Full Name" placeholder="eg: John Smith" value={formik.values.fullName} error={formik.errors.fullName} touched={formik.touched.fullName} onChange={formik.handleChange} disabled={formik.isSubmitting} onFormat={formatInputString} />

										{/* prettier-ignore */}
										<AppSelectInput required type="text" name="identificationType" label="ID Type" placeholder="Please Select" loadOptions={getIdentificationListing} value={formik.values.identificationType} error={formik.errors.identificationType} touched={formik.touched.identificationType} onChange={formik.handleChange} disabled={formik.isSubmitting} searchable={false} />

										{/* prettier-ignore */}
										<IdentificationField values={formik.values} errors={formik.errors} touched={formik.touched} onChange={formik.handleChange} disabled={formik.isSubmitting} isPassport={isPassport} validIdentificationType={validIdentificationType} />

										{/* prettier-ignore */}
										<AppDateInput required type="date-picker" name="dob" label="Date of Birth" placeholder="eg: 01/01/2024" value={formik.values.dob} error={formik.errors.dob} touched={formik.touched.dob} onChange={formik.handleChange}  disabled={formik.isSubmitting}  maxDate={new Date()} />
										
										{/* prettier-ignore */}
										<AppSelectInput required type="text" name="nationality" label="Nationality" placeholder="Please Select" loadOptions={getCountryListing} value={formik.values.nationality} error={formik.errors.nationality} touched={formik.touched.nationality} onChange={formik.handleChange} disabled={formik.isSubmitting} searchable={true} />

										{/* prettier-ignore */}
										<AppInput required type="text" name="email" label="Personal Email" placeholder="eg: abc@email.com" value={formik.values.email} prefixNo={formik.values.prefixNo} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} disabled={formik.isSubmitting} onFormat={formatEmailString} />
										
										{/* prettier-ignore */}
										<AppMobileInput required maxLength={12} type="text" name="mobileNo" prefixNoName="prefixNo" label="Contact Number" placeholder="eg: 121231234" value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} disabled={formik.isSubmitting} />

										{/* prettier-ignore */}
										<AppSelectInput required type="text" name="occupation" label="Occupation" placeholder="Please Select" loadOptions={getOccupationListing} value={formik.values.occupation} error={formik.errors.occupation} touched={formik.touched.occupation} onChange={formik.handleChange} disabled={formik.isSubmitting} searchable={true} />


										{/* Conditionally render university and student ID fields */}
  										{formik.values.occupation === "STUDENTS" && (
  										  <>
  										    <AppInput
  										      required
  										      type="text"
  										      name="instituteName"
  										      label="University/College Name"
  										      placeholder="e.g., University ABC"
  										      value={formik.values.instituteName}
  										      error={formik.errors.instituteName}
  										      touched={formik.touched.instituteName}
  										      onChange={formik.handleChange}
  										      disabled={formik.isSubmitting}
  										      onFormat={formatInputString}
  										    />
										
  										    <AppInput
  										      required
  										      type="text"
  										      maxLength={20}
  										      name="studentID"
  										      label="Student ID"
  										      placeholder="e.g., ABC12345"
  										      value={formik.values.studentID}
  										      error={formik.errors.studentID}
  										      touched={formik.touched.studentID}
  										      onChange={formik.handleChange}
  										      disabled={formik.isSubmitting}
  										      onFormat={formatInputvNumString}
  										    />
  										  </>
  										)}
									</div>

									<div className="enquiry__button-container">
										<AppPopOut label="Next" type="button" yesFunc={OnHandleYesFunc} noFunc={OnHandleNoFunc} />
									</div>
								</div>
							)}
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default PagePaForm;
