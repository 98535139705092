import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	personalForm: null,
	benefiForm: null,
	receipt: null,
	referralCode: null
};

const formSlice = createSlice({
	name: "form",
	initialState,
	reducers: {
		setPersonalForm(state, action) {
			return Object.assign(state, { personalForm: action.payload });
		},
		setBeneficiariesForm(state, action) {
			return Object.assign(state, { benefiForm: action.payload });
		},
		setReferralCode(state, action) {
			return Object.assign(state, { referralCode: action.payload });
		},
		setReceipt(state, action) {
			return Object.assign(state, { receipt: action.payload });
		},
		resetForm() {
            
			return initialState;
		},
	},
});

export const { setPersonalForm, setBeneficiariesForm, setReferralCode, setResult, resetForm, setReceipt } = formSlice.actions;

export default formSlice.reducer;