import React, { useCallback, useMemo, useRef, Fragment, memo } from 'react';
import { FormControl } from '@mui/material';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'common/class-names';

const AppDateInput = ({
    onChange, onClear, value, label, required, error, touched, className, disabled, multiline, name, placeholder, dateFormat, type, minDate, maxDate, ...props
}) => {

  function parseDateString(dateString) {
    if (!dateString) return null;

    let day, month, year;
    if (dateString.includes('/')) {
      // Format: dd/mm/yyyy
      [day, month, year] = dateString.split('/').map(part => parseInt(part, 10));
    } else if (dateString.includes('-')) {
      // Format: yyyy-mm-dd
      [year, month, day] = dateString.split('-').map(part => parseInt(part, 10));
    } else {
      console.error("Invalid date format");
      return null;
    }

    // Create the date object
    return new Date(year, month - 1, day);
  }

  // Convert value to Date object if it's a string
  const selectedDate = useMemo(() => {
    return typeof value === 'string' ? parseDateString(value) : value;
  }, [value]);

  const textFieldRef = useRef();
  const isErrorField = useMemo(() => !!error && !!touched, [error, touched]);
  const errorMessage = useMemo(() => (isErrorField ? error : ''), [error, isErrorField]);
  const combinedClassName = useMemo(() => {
    return classNames({
      'app-input': true,
      'app-input--disabled': disabled,
      'app-input--multiline': multiline,
      [className]: !!className,
    });
  }, [className, disabled, multiline]);

  // Handle the change event, including updating the selected date and invoking the onChange callback.
  const onHandleChange = useCallback((date) => {
    if (onChange) {
      const formattedDate = date ? formatDate(date) : '';
      onChange({ target: { value: formattedDate, name } });
    }
  }, [onChange, name]);

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const Footer = useCallback(() => (
    <Fragment>
      {errorMessage && <div className="app-date-input__error">{errorMessage}</div>} 
      {props.multiline && <span className="app-input__count">{`${props.value.length}/${props.maxLength}`}</span>}
    </Fragment>
  ), [errorMessage, props.multiline, props.value, props.maxLength]);

  return (
    <div className={combinedClassName}>
      <FormControl error={isErrorField}>
        <label htmlFor={name} className="app-date-input__label">
          {label}
          {required && <span className="app-date-input__required">*</span>}
        </label>
        <DatePicker
          id={type}
          selected={selectedDate}
          onChange={onHandleChange}
          dateFormat={dateFormat}
          placeholderText={placeholder}
          ref={textFieldRef}
          minDate={minDate}
          maxDate={maxDate}
          closeOnScroll={false}
          disabled={disabled}
          popperPlacement='bottom-center'
          {...props}
        />
        {Footer()}
      </FormControl>
    </div>
  );
};

AppDateInput.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  dateFormat: PropTypes.string,
  isClearable: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

AppDateInput.defaultProps = {
  disabled: false,
  required: false,
  className: '',
  value: null,
  onClear: null,
  type: 'text',
  placeholder: '',
  dateFormat: 'dd/MM/yyyy',
  touched: false,
  error: '',
  isClearable: false,
};

export default memo(AppDateInput);