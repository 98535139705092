import React, { useEffect, useMemo, useCallback, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import COMMON from "common";
import api from "services/api";
import ERRORS from "common/errors";
import pathnames from "routes/pathnames";
import serveRequestErrors from "common/serve-request-errors";
import formatInputString from "common/format-input-string";
import formatPassportString from "common/format-passport-string";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppMaskingInput from "components/app-masking-input";
import AppLoading from "components/pages/page-enquiry/app-loading";
import AppCardStatus from "components/pages/page-enquiry/app-card-status";
import getIdentificationListing from "services/get-identification-listing";
import getCountryListing from "services/get-country-listing";
import AppMobileInput from "components/app-mobile-input";
import AppDateInput from "components/app-date-input";
import { setBeneficiariesForm, setReceipt } from "store/slices/form";


const PageBeForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const form = useSelector((state) => state.form);
	const [messageType, setMessageType] = useState("");
	const [errorCode] = useState("");
	//const location = useLocation();
	//const urlParams = new URLSearchParams(location.search);
	//const referralCode = urlParams.get("myReferralCode") || "";

	

	const initialValues = useMemo(() => {
		const info = form?.benefiForm;
		const isInfoPassport = info?.identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT;

		let payload = {
			fullName: "",
			identificationType: "NRIC",
			passport: "",
			nric: process.env.REACT_APP_ID,
			dob: "",
			nationality: "",
			mobileNo: "",
			prefixNo: "",
			
		};

		if (info) {

			if (info.identificationType) payload.identificationType = info.identificationType;

			if (info.identificationNo) {
				if (isInfoPassport) {
					payload.passport = info.identificationNo;
				} else {
					payload.nric = info.identificationNo;
				}
			}

			if (info.fullName) payload.fullName = info.fullName;

			if (info.dob) payload.dob = info.dob;

			if (info.nationality) payload.nationality = info.nationality;
			
			if (info.mobileNo) payload.mobileNo = info.mobileNo;
			
			if (info.prefixNo) payload.prefixNo = info.prefixNo;
		}

		return payload;
	}, [form]);

	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			fullName: yup.string().required(ERRORS.REQUIRED),
			identificationType: yup.string().required(ERRORS.REQUIRED),
			passport: yup.string().when(["identificationType"], {
				is: (identificationType) => identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
			nric: yup.string().when(["identificationType"], {
				is: (identificationType) => identificationType === COMMON.IDENTIFICATION_TYPE.NRIC,
				then: () => yup.string().required(ERRORS.REQUIRED).min(14, ERRORS.NRIC),
			}),
			dob: yup.string().required(ERRORS.REQUIRED),
			nationality: yup.string().required(ERRORS.REQUIRED),
			mobileNo: yup.string().required(ERRORS.REQUIRED).min(9, ERRORS.MOBILE),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});


	const isPassport = useMemo(() => formik.values.identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT, [formik.values.identificationType]);
	const validIdentificationType = useMemo(() => !!formik.values.identificationType, [formik.values.identificationType]);

	const onHandleNavigate = useCallback(() => {
		navigate(pathnames.receipt);
	}, [navigate]);

	const onHandleSubmit = async (values) => {
		let response = null;
		const personalinfo = form?.personalForm;
		const referralCode = form?.referralCode;

		try {
			let payload = {
				fullName: values.fullName,
				identificationType: values.identificationType,
				identificationNo: isPassport ? values.passport : values.nric,
				//dob: isPassport ? null : getDateOfBirthNRIC(values.nric),
				dob: new Date(parseDateString(values.dob)),
				nationality: values.nationality,
				mobileNo: values.prefixNo + values.mobileNo,
			};
			dispatch(setBeneficiariesForm(payload));
			//if (referralCode && referralCode !== "null") dispatch(setReferralCode(referralCode));

			let combinePayload = {
				PersonalInfo: personalinfo,
				Beneficiary: payload,
				ReferralCode: {referralCode: referralCode},
			}

			response = await api.post.pa.fullmanualEnquiry(combinePayload);
		} catch (error) {
			
			if (!error?.response?.data?.errorCode) {
				if (error?.code === COMMON.ERRORS.NETWORK_ERROR) {
					setMessageType(COMMON.MESSAGE_TYPE.NETWORK);
				} else if (error?.code === COMMON.ERRORS.BE_BAD_RESPONSE) {
					setMessageType(COMMON.MESSAGE_TYPE.INVALID);
				}
			} else {
				serveRequestErrors(error);
			}
		} finally {
			formik.setSubmitting(false);
		}

		if(response)
		{
			dispatch(setReceipt(response));
			// Set the navigation flag
			sessionStorage.setItem('fromBe', 'true');
			sessionStorage.removeItem('fromPa');
			onHandleNavigate();
		}
	};

	const parseDateString = (dateString) => {
		const [day, month, year] = dateString.split('/').map(Number);
		// Note: Months are 0-indexed in JavaScript Date objects
		return new Date(Date.UTC(year, month - 1, day));
	} ;
	

	const IdentificationField = useCallback((obj) => {
		if (!obj.isPassport && obj.validIdentificationType) {
			/* prettier-ignore */
			return <AppMaskingInput required type="text" name="nric" label="NRIC No." placeholder="e.g. 901010-14-1234" disabled={obj.disabled} value={obj.values.nric} error={obj.errors.nric} touched={obj.touched.nric} onChange={obj.onChange} format={COMMON.MASKING.NRIC} />
		}

		if (obj.isPassport && obj.validIdentificationType) {
			/* prettier-ignore */
			return <AppInput required type="text" name="passport" label="Passport" placeholder="e.g. VD1289281" disabled={obj.disabled} value={obj.values.passport} error={obj.errors.passport} touched={obj.touched.passport} onFormat={formatPassportString} onChange={obj.onChange} />
		}

		return null;
	}, []);


	useEffect(() => {
		// Check if the session flag is set
		const fromPa = sessionStorage.getItem('fromPa');
	
		if (!fromPa) {
		  // If no session flag, redirect to home page
		  navigate(pathnames.home, { replace: true });
		}
	
		// Add event listener to handle page refresh
		const handleBeforeUnload = () => {
		  sessionStorage.removeItem('fromPa');
		};
	
		window.addEventListener('beforeunload', handleBeforeUnload);
	
		// Cleanup the event listener on component unmount
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	  }, [navigate]);


	return (
		<div className="page-beneficiaries-form">
			<div className="enquiry">
				<form className="enquiry__form" onSubmit={formik.handleSubmit}>
					<div className="enquiry__container">
						<div className="enquiry__box">
							{!messageType && !formik.isSubmitting && (
								<div className="enquiry__wrapper">
									<h1 className="enquiry__title">Beneficiaries Details</h1>
									<p className="enquiry__description">Submit your beneficiaries information.</p>

									<div className="enquiry__box-body">

										{/* prettier-ignore */}
										<AppInput required type="text" name="fullName" label="Full Name" placeholder="eg: John Smith" value={formik.values.fullName} error={formik.errors.fullName} touched={formik.touched.fullName} onChange={formik.handleChange} disabled={formik.isSubmitting} onFormat={formatInputString} />

										{/* prettier-ignore */}
										<AppSelectInput required type="text" name="identificationType" label="ID Type" placeholder="Please Select" loadOptions={getIdentificationListing} value={formik.values.identificationType} error={formik.errors.identificationType} touched={formik.touched.identificationType} onChange={formik.handleChange} disabled={formik.isSubmitting} searchable={false} />

										{/* prettier-ignore */}
										<IdentificationField values={formik.values} errors={formik.errors} touched={formik.touched} onChange={formik.handleChange} disabled={formik.isSubmitting} isPassport={isPassport} validIdentificationType={validIdentificationType} />

										{/* prettier-ignore */}
										<AppDateInput required type="date-picker" name="dob" label="Date of Birth" placeholder="eg: 01/01/2024" value={formik.values.dob} error={formik.errors.dob} touched={formik.touched.dob} onChange={formik.handleChange}  disabled={formik.isSubmitting} maxDate={new Date()} />
										
										{/* prettier-ignore */}
										<AppSelectInput required type="text" name="nationality" label="Nationality" placeholder="Please Select" loadOptions={getCountryListing} value={formik.values.nationality} error={formik.errors.nationality} touched={formik.touched.nationality} onChange={formik.handleChange} disabled={formik.isSubmitting} searchable={true} />

										{/* prettier-ignore */}
										<AppMobileInput required maxLength={12} type="text" name="mobileNo" prefixNoName="prefixNo" label="Contact Number" placeholder="eg: 121231234" value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} disabled={formik.isSubmitting} />
										
										
										
									</div>

									

									<div className="enquiry__button-container">
										<AppButton type="submit" label="Submit" disabled={formik.isSubmitting } />
									</div>
								</div>
							)}

							<AppLoading isLoading={formik.isSubmitting} text="Processing page during submiting your form." />

							<AppCardStatus messageType={messageType} error={errorCode} setMessageType={setMessageType} />
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default PageBeForm;
