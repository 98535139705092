import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import classNames from "common/class-names";
import AppButton from "components/app-button";

const AppPopOutComponent = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const className = useMemo(() => {
    return classNames({
      "app-button": true,
      "app-button--outline": props.outline || false,
      ...(props.className && {
        [props.className]: true,
      }),
    });
  }, [props.className, props.outline]);

  const togglePopOut = (event) => {
    setIsOpen(!isOpen);
  };

  const debouncePress = debounce(togglePopOut, 500);

  const handleYesClick = () => {
    props.yesFunc(); // Call the form submission function
    setIsOpen(false); // Close the pop-out
  };

  const handleNoClick = () => {
    props.noFunc(); // Execute the no function logic
    setIsOpen(false); // Close the pop-out
  };


  return (
    <div className="app-pop-out">
      <AppButton
        type={props.type}
        disabled={props.disabled}
        onClick={debouncePress}
        className={className}
        label={props.label}
      />

      {isOpen && (
        <div className="popout">
          <div className="popout__content">
            <h2 className="popout__title">Beneficiary Details</h2>
            <p className="popout__descript">Do you want to fill in beneficiary details?</p>

            <div className="popout__button">
              <AppButton
                outline
                label="No"
                type="button"
                onClick={handleNoClick}
              />

              <AppButton
                label="Yes"
                type="button"
                onClick={handleYesClick}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

AppPopOutComponent.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["button", "submit", "reset"]).isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  yesFunc: PropTypes.func.isRequired,
  noFunc: PropTypes.func.isRequired,
};

export default AppPopOutComponent;