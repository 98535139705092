const pathnames = {
	home: "/",
	appStore: "/ec/appStore",
	paForm: "/pa/form/indi",
	beForm: "/pa/form/be",
	receipt: "/pa/form/receipt",
	decript: "/pa/form/decript",
};

export default pathnames;
